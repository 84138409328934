<!--
 * @Description: 开发票 可开票订单 paymentHistory
 * @Author: yanxiao
 * @Github:
 * @Date: 2021-06-07 11:28:00
 * @LastEditors: zhoucheng
-->
<template>
  <div class='body'>
    <van-nav-bar title="可开票订单"
                 left-text="返回"
                 left-arrow
                 @click-left="handleClickTopBack" />
    <van-row class="main">
      <van-checkbox-group v-model="result"
                          ref="checkboxGroup"
                          @change="groupChange">
        <van-row class="list"
                 v-for="(item,index) in payRecordList"
                 :key=index>
          <van-col :span="3">
            <van-checkbox icon-size="17px"
                          style="margin:29px 0 0 12px"
                          :name="item">
            </van-checkbox>
          </van-col>
          <van-col :span="21">
            <van-row style="margin-top:10px;font-size:15px;color: #333333;">
              <van-image :src="require('@/assets/wxInvoice/shijian.png')"
                         width="15"
                         height="15" />
              <span style="margin-left:5px;">{{item.paymentTime}}</span>
            </van-row>
            <van-row style="margin-top:15px;font-size: 15px;color: #666666;">
              <van-col :span="17">
                支付方式：{{item.payMethod}}
              </van-col>
              <van-col style="font-size: 21px;color: #333333;text-align:right"
                       :span="5">
                {{item.paymentMoneyAmount}}
              </van-col>
              <van-col style="font-size: 17px;color: #666666;"
                       :span="1">元</van-col>
            </van-row>
          </van-col>
        </van-row>
      </van-checkbox-group>
    </van-row>
    <van-row class="selectAllInvoice">
      <van-col :span="6">
        <van-checkbox v-model="selectAll"
                      @click="changeAll(selectAll)"
                      icon-size="17px"
                      style="margin:12px 0 0 12px;font-size:15px;color: #333333;">
          {{selectAll? '全不选':'全选'}}
        </van-checkbox>
      </van-col>
      <van-col :span="5"
               style="font-size:13px;color:#666666;">
        开票金额：
      </van-col>
      <van-col :span="3"
               style="font-size:19px;color:#19A9FC;text-align:right">
        {{money}}
      </van-col>
      <van-col :span="1"></van-col>
      <van-col :span="2"
               style="font-size:15px;color: #333333;">
        元
      </van-col>
      <van-col :span="7"
               style="font-size:17px;color: #FFFFFF;background:#19A9FC;text-align: center;"
               @click="handleClickNext">
        下一步
      </van-col>
    </van-row>
  </div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
// 例如：import 《组件名称》 from '《组件路径》';
import getUserInfo from '@/utils/getUserInfo.js'

export default {
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    // 这里存放数据
    return {
      money: '0.00', // 开票金额
      selectAll: '', // 全选或全不选
      result: [], // 多选绑定数组
      payRecordList: [], // 可开票订单获取数组
      valOrder: [], // 订单数组
      paymentSequenceList: [], // 选择订单流水号
      listLength: 0, // 数据总数
      userInfo: {}
    }
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created () {
    getUserInfo().then(() => {
      this.getUserInfoDetails()
    })
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.getPaymentRecordToInvoice()
  },
  beforeCreate () { }, // 生命周期 - 创建之前
  beforeMount () { }, // 生命周期 - 挂载之前
  beforeUpdate () { }, // 生命周期 - 更新之前
  updated () { }, // 生命周期 - 更新之后
  beforeDestroy () { }, // 生命周期 - 销毁之前
  destroyed () { }, // 生命周期 - 销毁完成
  activated () { }, // 如果页面有keep-alive缓存功能，这个函数会触发
  // 方法集合
  methods: {
    // 获取个人信息详情
    getUserInfoDetails () {
      this.$selfInfo.getUserInfo({}).then(res => {
        this.userInfo = res.resultEntity
      })
    },
    // 获取可开票记录
    getPaymentRecordToInvoice () {
      this.$invoiceApi.getPaymentRecordToInvoice().then(res => {
        res.resultEntity.forEach(item => {
          item.paymentMoneyAmount = (item.paymentMoneyAmount / 100).toFixed(2)
        })
        this.payRecordList = res.resultEntity
        this.listLength = res.resultEntity.length
      })
    },
    // 多选组发生改变
    groupChange (names) {
      if (names.length < this.listLength) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
      let money = 0
      this.valOrder = names
      names.forEach(item => {
        money += Number(item.paymentMoneyAmount)
      })
      this.money = money.toFixed(2)
    },
    // 全选或全不选
    changeAll (state) {
      this.$refs.checkboxGroup.toggleAll(state)
    },
    // 点击下一步
    handleClickNext () {
      // 开票金额不能为0
      if (Number(this.money) === 0) {
        this.$toast('请选择开票订单！')
      } else {
        // 已经绑定手机号才可以点击下一步
        if (this.userInfo.phoneNumber) {
          this.valOrder.forEach(item => {
            this.paymentSequenceList.push(item.paymentSequence)
          })
          this.$router.push({
            name: 'fillInvoice',
            query: {
              invoiceMoney: this.money,
              paymentSequenceList: this.paymentSequenceList,
              userInfo: JSON.stringify(this.userInfo)
            }
          })
        } else {
          // 未绑定手机号
          this.$dialog.confirm({
            message: "请您先确认进入'渝悦停'公众号个人中心模块绑定手机号再进行开票服务"
          }).then(() => {
            // 跳转手机换绑
            this.$router.push({
              name: 'selfChangePhone',
              query: {
                phoneNumber: this.userInfo.phoneNumber,
                userInfo: JSON.stringify(this.userInfo)
              }
            })
          }).catch(() => {
            this.$toast('您已点击取消')
          })
        }
      }
    },
    // 顶部返回
    handleClickTopBack () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='less' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  .main {
    width: 100%;
    height: calc(100% - 70px - 46px - 10px);
    margin-top: 10px;
    overflow: auto;
    .list {
      width: 100%;
      height: 79px;
      background-color: #ffffff;
      border-bottom: 1px solid #e7e8e8;
    }
  }
}
.selectAllInvoice {
  width: 100%;
  height: 46px;
  line-height: 46px;
  background-color: white;
  position: absolute;
  bottom: 0;
}
</style>
